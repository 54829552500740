@import "src/Assets/styles/mixins";
@import "src/Assets/styles/variables";

.avatar-block {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: $BASE-FONT-SIZE;
  color: $BASE-GREY-COLOR;

  &--name {
    @include circularBlock();
    margin: 0 4px;
    cursor: pointer;
    color: $BASE-WHITE-COLOR;
  }

  &--email {
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 200px;
    white-space: nowrap;
  }
}
