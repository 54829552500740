@import "src/Assets/styles/variables";
@import "src/Assets/styles/mixins";

.no-permission {
  max-width: 900px;
  text-align: center;
  padding: 20px 50px;
  color: $BASE-GREY-COLOR;

  &-title {
    font-size: 18px;
  }

  &--email {
    padding: 20px 0;
    color: $BASE-ERROR-COLOR;
  }
}
