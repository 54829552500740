@import "src/Assets/styles/variables";
@import "src/Assets/styles/mixins";

.initiative-mobile-bar {
  position: fixed;
  width: 50px;
  height: 100%;
  background-color: transparent;
  z-index: 20;

  &--header-open {
    width: 143px;
    background-color: $BASE-WHITE-COLOR;
  }

  &--items {
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    width: 0;
    height: calc(100% - 100px);
    background-color: #eeeeee;
    transition: all 0.5s ease-in-out;
  }

  &--items-open {
    width: 143px;
  }

  &--open {
    width: 100%;
  }

  transition: all 0.5s ease-in-out;

  .menu-btn {
    @include flex-center;
    position: relative;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
  }

  .menu-btn__burger {
    width: 26px;
    height: 3px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    transition: all 0.5s ease-in-out;
    background-color: $BASE-GREY-COLOR;
  }

  .menu-btn__burger::before,
  .menu-btn__burger::after {
    content: "";
    position: absolute;
    width: 26px;
    height: 3px;
    background-color: $BASE-GREY-COLOR;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(255, 101, 47, 0.2);
    transition: all 0.5s ease-in-out;
  }

  .menu-btn__burger::before {
    transform: translateY(-8px);
  }

  .menu-btn__burger::after {
    transform: translateY(8px);
  }

  /* ANIMATION */
  .menu-btn.open .menu-btn__burger {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }

  .menu-btn.open .menu-btn__burger::before {
    transform: rotate(45deg) translate(35px, -35px);
  }

  .menu-btn.open .menu-btn__burger::after {
    transform: rotate(-45deg) translate(35px, 35px);
  }
}
