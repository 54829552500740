.WuProductDropdown-module_productDropdownToggleButton__N1cbs {
  padding: 10px;
}

.WuBreadcrumbs-module_anchor__n-yRg:last-child{
  color: #ffffff !important;
}

.base-header {
  position: fixed;
  width: 100%;
  z-index: 1000;
}