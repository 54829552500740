@import "src/Assets/styles/variables";
@import "src/Assets/styles/mixins";
@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: 'Fira Sans', sans-serif;
  @include scrollbar;
}

html,
body,
div#root,
div.app {
  height: 100%;
  background-color: $BASE-WHITE-COLOR;
}


.main-container {
  min-height: 100%;
  padding-top: $BASE-HEADER-HEIGHT;
}

.no-data {
  @include center-block();
}

.error-message {
  @include center-block();
  text-align: center;
  color: $BASE-ERROR-COLOR;
}

.table-fix-head {
  z-index: 2;
  height: 400px;
  overflow: auto;

  .qp-table-head {
    position: sticky;
    top: 0;
    z-index: 1;
  }
}

.table-operation {
  @include flex-center-space-between;
  max-width: 100px;
  margin: 0 auto;
  opacity: 0;
}

.table-link-name {
  @include reduceText(400px);
  cursor: pointer;
  color: $BASE-BLUE-COLOR !important;
}

.table-pagination-loading {
  position: relative;
  height: 80px;
}


button {
  position: relative;
  font-size: 12px;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

button[disabled] {
  cursor: default;
  background-color: #ddd;
  pointer-events: none;
}

.round-button {
  position: fixed;
  right: 20px;
  bottom: 40px;
  z-index: 11;
}

.small-round-button {
  @include flex-center;
  width: 22px;
  height: 22px;
  border: 1px solid $BASE-BLUE-COLOR;
  border-radius: 50%;

  &:hover {
    background-color: $BASE-BLUE-COLOR;

    svg {
      fill: $BASE-WHITE-COLOR;
    }
  }
}

.user-log-btn-block {
  display: flex;
}

.log-btn {
  text-decoration: underline;
  font-weight: 300;
  color: $BASE-GREY-COLOR;
}

.user-log-btn {
  font-weight: 600;
  color: $BASE-GREY-COLOR;
}

.base-header-page {
  @include flex-center-space-between;
  position: fixed;
  width: 100%;
  height: 48px;
  padding: 0 20px;
  border-bottom: 1px solid $BASE-BORDER-COLOR;
  z-index: 10;
  color: $BASE-GREY-COLOR;
  background-color: $BASE-WHITE-COLOR;

  &--go-back {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.base-title {
  font-size: 16px;
  padding-bottom: 16px;
  color: $BASE-GREY-COLOR;
}

.base-subtitle {
  padding-bottom: 10px;
  font-size: $BASE-FONT-SIZE;
  color: $BASE-LIGHT-GREY-COLOR;
}

.validation-error {
  border-bottom: 1px solid $BASE-ERROR-COLOR !important;
}

.filter-tags {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: #e8e8e8;
  font-size: $BASE-FONT-SIZE;
  color: $BASE-GREY-COLOR;

  svg {
    cursor: pointer;
    margin-left: 8px;
    width: 10px;
  }
}


.status-icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.active-status {
  background: #9fe4ac;
  border: 1px solid #3fca5a;
}

.inactive-status {
  background: #8dc3f2;
  border: 1px solid $BASE-BLUE-COLOR;
}

.closed-status {
  background-color: $BASE-BORDER-COLOR;
  border: 1px solid #9b9b9b;
}

.red {
  background: $BASE-ERROR-COLOR;
  padding: 2px;
}

.green {
  background: $BASE-SUCCESS-COLOR;
  padding: 2px;
}

.yellow {
  background: $BASE-NORMAL-COLOR;
  padding: 2px;
}

.icon-btn {
  @include flex-center;
  gap: 10px;
}

.initiative-progress {
  width: 100%;
  height: 8px;
  border-radius: 50px;
  background-color: $BASE-BORDER-COLOR;

  &--filled-block {
    position: relative;
    border-radius: 50px;
    height: 8px;
    background-color: $BASE-SUCCESS-COLOR;
  }

  &--start-percent {
    position: absolute;
    bottom: -20px;
    transform: translate(0, -40%);
  }

  &--filled-percent {
    left: 50%;
    transform: translate(-50%, -40%);
  }
}

.fish-bone {
  max-width: 850px;
  width: 100%;

  &--scales {
    min-width: 790px;
  }

  &--spine {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: $BASE-LIGHT-GREY-COLOR;
  }

  &--spine-title {
    display: flex;
    position: absolute;
    top: -10px;
    left: 860px;
    word-wrap: break-word;

    &:hover {
      .fish-bone--spine-title-edit {
        display: inline-block;
      }
    }
  }

  &--spine-title-edit {
    display: none;
    margin-left: 20px;
  }

  &--scales {
    display: flex;
  }

  &--scale {
    position: relative;
    width: calc(90% / 3);
    height: 252px;

    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 258px;
      background-color: $BASE-LIGHT-GREY-COLOR;
    }
  }


  &--scale-content {
    position: absolute;
    right: 58px;
    display: flex;
    align-items: center;
    gap: 20px;
    z-index: 10;
  }

  &--scale-name {
    @include reduceText(130px)
  }

  &--top-thorn, &--bottom-thorn {
    width: 168px;
    display: flex;
    align-items: center;
    position: absolute;
    top: var(--i);
    right: var(--j);

    &:hover {
      .popover-icon {
        opacity: 1;
      }
    }

    .popover-icon {
      opacity: 0;
      top: 0;
      left: 4px;
    }

    &--popover-block {
      position: absolute;
      right: 22px;
      top: 1px;
    }
  }

  &--top-thorn-title, &--bottom-thorn-title {
    @include reduceText(152px);
    min-width: 152px;
    padding: 4px 8px;
    background-color: $BASE-BG-COLOR;
  }

  &--top-thorn-line, &--bottom-thorn-line {
    width: 24px;
    height: 1px;
    background-color: $BASE-LIGHT-GREY-COLOR;
  }

  &--top-thorn-comments {
    @include flex-center;
    text-transform: uppercase;
    border-radius: 4px;
    top: -16px;
    right: 16px;
    position: absolute;
    padding: 2px;
    font-size: 10px;
    width: 24px;
    height: 20px;
    border: 1px solid $BASE-BG-COLOR;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    z-index: 10;
    cursor: pointer;
    color: $BASE-WHITE-COLOR;
    background-color: var(--i);

    &::after {
      content: '';
      position: absolute;
      top: 17px;
      right: 16px;
      width: 0;
      height: 0;
      border-top: 0 solid transparent;
      border-left: 6px solid var(--i);
      border-bottom: 6px solid transparent;
    }
  }


  .top-scale {
    &::after {
      top: 10px;
      right: 32px;
      transform: rotate(330deg);
    }
  }

  .top-scale-content {
    top: 0;
  }

  .bottom-scale {
    &::after {
      bottom: 12px;
      right: 32px;
      transform: rotate(30deg);
    }
  }

  .bottom-scale-content {
    bottom: 0;
  }

  &--input {
    width: 112px;
  }

  &--causes-btn {
    position: absolute;
    top: var(--i);
    right: var(--j);
    width: 46px;
    height: 24px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    color: $BASE-GREY-COLOR;
    background-color: $BASE-BG-COLOR;

    &:hover {
      color: $BASE-WHITE-COLOR;
      background-color: $BASE-LIGHT-GREY-COLOR;
    }

    &--title {
      width: 100%;
    }
  }
}


svg, label {
  cursor: pointer;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
}

h1, h2, h3 {
  all: unset;
  display: block;
}

input[type="checkbox"] {
  cursor: pointer !important;
}

input[type=color]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
  padding: 0;
}

input[type=color] {
  all: unset;
  border-radius: 50%;
  min-width: 24px;
  min-height: 24px;
  display: inline-block;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-weight: 100;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  font-weight: 100;
}

::-ms-input-placeholder { /* Microsoft Edge */
  font-weight: 100;
}

@include media('lg') {
  .fish-bone {
    overflow-x: auto;
  }
}

@include media('md') {
  .table-fix-head {

    .qp-table-head {
      position: absolute;
    }
  }
  .initiative-progress {
    width: 250px;
  }
  .yellow {
    padding: 16px;
  }
  .fish-bone {
    display: flex;
    flex-direction: column;

    &--spine {
      order: 2;
      margin-top: 20px;

      &-title {
        position: unset;
      }
    }
  }
}




