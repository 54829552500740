@import "src/Assets/styles/mixins";
@import "src/Assets/styles/variables";

.base-nav-bar {
  position: relative;
  display: flex;
  gap: 8px;
  width: 100%;
  max-height: 34px;
  background-color: $BASE-WHITE-COLOR;

  &--mobile {
    display: none;
  }

  &--tab {
    display: flex;
    align-items: center;
    height: 34px;
    gap: 8px;
    color: $BASE-LIGHT-GREY-COLOR;
    border-bottom: 1px solid transparent;
    font-size: 14px;
    padding: 0 8px;
    cursor: pointer;
    @media screen and (max-width: $medium) {
      padding: 0;
    }
  }

  &--tab-active {
    z-index: 1;
    border-bottom: 1px solid $BASE-BLUE-COLOR;
    color: $BASE-BLUE-COLOR;
  }

  &--tab-delete {
    position: absolute;
    right: 0;
  }

  &--value {
    text-align: center;
    line-height: 24px;
    font-size: 12px;
    width: 24px;
    height: 24px;
    background-color: #e8e8e8;
  }

  &-hr {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: $BASE-BORDER-COLOR;
  }
}

//mobile

@include  media('xs'){
  .base-nav-bar {
    overflow: hidden;
    width: 100%;
    max-height: 28px;
    padding: 0 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #eeeeee;
    transition: 0.3s;
    &--open {
      max-height: 100vh;
      z-index: 9;
    }
    &--mobile {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      color: $BASE-GREY-COLOR;
    }
    &--tab {
      @include flex-center-space-between;
      width: 100%;
    }
    &-hr {
      display: none;
    }
    &--tab-delete {
      position: static;
      flex-direction: initial;
    }
  }
}
