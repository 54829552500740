@import "src/Assets/styles/variables";
@import "src/Assets/styles/mixins";

.log-history-header {
  width: 100%;
  height: 1px;
  margin: 20px 0;
  background-color: $BASE-BORDER-COLOR;
}

.log-history {
  &--log-box {
    gap: 20px;
    padding: 12px 10px;
    font-size: $BASE-FONT-SIZE;

    &:hover {
      .user-log-btn, .log-btn {
        color: $BASE-BLUE-COLOR;
      }

      background-color: #F5F5F5;
    }
  }

  &--date {
    margin-left: 20px;
    font-weight: 300;
  }

  &--history {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 80%;
    line-height: 20px;
    font-weight: 300;
    margin-bottom: 8px;
  }
}

@include media('xs') {
  .log-history {
    &--history {
      flex-wrap: wrap;
    }
    &--date {
      margin-left: 0;
    }
  }
}