@import "src/Assets/styles/variables";


@mixin relative-content {
  position: relative;
  min-height: calc(100vh - 48px);
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-column-space-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin flex-center-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin flex-one {
  display: flex;
  flex: 1;
}

@mixin center-block {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin scrollbar {
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #c0c0c0;
  }
}

@mixin scrollbarNone {
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: transparent;
  }
}

@mixin circularBlock {
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  border-radius: 50%;
}

@mixin lead-to-right {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

@mixin axis-block {
  position: relative;
  width: 220px;
  height: 220px;
  border-radius: 8px;
  @media screen and (max-width: $medium) {
    width: 90px;
    height: 90px;
  }
}

@mixin line-clamp($clamp) {
  display: -webkit-box;
  -webkit-line-clamp: $clamp;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin reduceText($width) {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: $width;
}

@mixin base-btn-2 {
  all: unset;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 30px;
  padding: 0 4px;
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
  color: $BASE-BLUE-COLOR;
  border: 1px solid $BASE-BLUE-COLOR;
}

@mixin base-btn-3 {
  @include flex-center();
  min-width: 20px;
  min-height: 20px;
  border: 2px solid $BASE-GREY-COLOR;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
}

@mixin base-wrapper {
  height: 100%;
  padding: 38px 60px 0;
}

@mixin input-number-appearance-none {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

@mixin small-modal($width:80vw,$maxWidth:600px,$height:300px,) {
  width: $width;
  max-width: $maxWidth;
  height: $height;
  padding: 20px;
}

@mixin small-modal-content($height:160px) {
  height: $height;
  margin-top: 10px;
  overflow: auto;
  @include scrollbar;
}
//media

$breakPoints: (
        3 xs: (
                start: null,
                end: '350px',
        ),
        xxs: (
                start: null,
                end: '425px',
        ),
        xs: (
                start: null,
                end: '590px',
        ),
        sm: (
                start: null,
                end: '768px',
        ),
        md: (
                start: null,
                end: '924px',
        ),
        lg: (
                start: null,
                end: '1024px',
        ),
        xl: (
                start: null,
                end: '1440px',
        ),
        xxl: (
                start: '1441px',
                end: null,
        ),
);

@mixin media($breakpoint) {
  $value: map-get($breakPoints, $breakpoint);
  @if $value != null {
    $start: map-get($value, start);
    $end: map-get($value, end);

    @if $start == null {
      @media (max-width: $end) {
        @content;
      }
    } @else if $end == null {
      @media (min-width: $start) {
        @content;
      }
    }
  } @else {
    @warn "Invalid breakpoint `#{$breakpoint}`.";
  }
}

