$BASE-GREY-COLOR: #545e6b;
$BASE-LIGHT-GREY-COLOR: #9b9b9b;
$BASE-BLUE-COLOR: #1b87e6;
$BASE-DARK-BLUE-COLOR: #1b3380;
$BASE-BG-COLOR: #f5f5f5;
$BASE-WHITE-COLOR: #ffffff;
$BASE-BORDER-COLOR: #d8d8d8;
$BASE-NAVBAR-WIDTH: 143px;
$BASE-HEADER-HEIGHT: 48px;
$BASE-MOBILE-HEADER-HEIGHT: 100px;
$BASE-FONT-SIZE: 12px;
$MODAL-MIDDLE-SIZE: 16px;
$BASE-LARGE-SIZE: 24px;
$BASE-ERROR-COLOR: #e53251;
$BASE-SUCCESS-COLOR: #3fca5a;
$BASE-NORMAL-COLOR: #f0b400;
$MAIN-GRAY-COLOR: #454545;
$MAIN-BLUE-COLOR: #1b3380;

$large-l: 1440px;
$large: 1024px;
$medium: 768px;
$small-L: 590px;
$small: 425px;
