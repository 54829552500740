@import "src/Assets/styles/variables";
@import "src/Assets/styles/mixins";

.base-modal {
  padding: 0 !important;

  &--header {
    @include flex-center;
    gap: 8px;
    height: 40px;
    line-height: 40px;
    color: $BASE-GREY-COLOR;
    background-color: #e8e8e8;
    font-size: $MODAL-MIDDLE-SIZE;

    &--title {
      @include reduceText(320px);
    }
  }


  .react-responsive-modal-closeButton {
    top: 12px !important;
    right: 12px !important;
  }
}

.react-responsive-modal-modal {
  max-width: unset !important;
}
