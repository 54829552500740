@import "src/Assets/styles/variables";
@import "src/Assets/styles/mixins";

.pagination-container {
  display: block;
  margin: 10px 0;
  position: relative;

  button {
    background: none;
    border: none;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    font-size: 12px;
    color: $BASE-GREY-COLOR;
  }

  text-align: center;

  &-button {
    width: 115px;
    height: 32px;
    border-radius: 3px;
    margin: 0 10px;

    &:hover {
      background: #f0f0f0;
    }
  }

  &-open-button {
    background: #f0f0f0;
  }

  .open-button {
    background: #f0f0f0;
  }

  &-button-arrow {
    width: 24px;
    height: 24px;

    &:hover {
      background: #f0f0f0;
    }
  }

  &-popover {
    height: 0;
    width: 0;
    overflow: hidden;
  }

  .open-popover {
    position: absolute;
    left: 50%;
    bottom: 38px;
    transform: translate(-50%, 0);
    margin: 0 auto;
    height: auto;
    max-height: 400px;
    overflow: auto;
    width: 180px;
    font-size: 12px;
    z-index: 1000;
    box-shadow: 0 0 8px 0 $BASE-GREY-COLOR;
    color: $BASE-GREY-COLOR;
    background-color: $BASE-WHITE-COLOR;
  }

  &-popover-button {
    width: 100%;
    padding: 9px 20px 7px !important;

    &:hover {
      background: #f0f0f0;
    }
  }

  &-button-container {
    @include flex-center
  }
}

.pagination-loading {
  display: none;
}
