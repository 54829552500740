@import "src/Assets/styles/variables";
@import "src/Assets/styles/mixins";


.initiative-bar-wrapper {
  position: relative;
}

.initiative-bar-toolbar {
  width: 50px;
  height: 100%;
}

.initiative-bar-container {
  @include flex-column-space-between;
  flex: 1;
  position: fixed;
  width: 50px;
  height: calc(100% - #{$BASE-HEADER-HEIGHT});
  z-index: 20;
  background: #eeeeee;
  overflow: hidden;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    width: $BASE-NAVBAR-WIDTH;
  }

  &-icon {
    width: 16px;
  }

  &-navLink {
    display: flex;
    align-items: center;
    gap: 9px;
    cursor: pointer;
    padding: 0 17px;
    height: $BASE-HEADER-HEIGHT;
    font-size: $BASE-FONT-SIZE;
    color: $BASE-GREY-COLOR;

    &:hover {
      background: $BASE-BORDER-COLOR;
    }
  }

  &-title {
    padding: 10px;
    white-space: nowrap;
  }

  .active {
    background-color: $BASE-WHITE-COLOR;
    color: $BASE-BLUE-COLOR;

    path, polygon, rect {
      fill: $BASE-BLUE-COLOR;
    }
  }
}