@import "src/Assets/styles/variables";
@import "src/Assets/styles/mixins";

.user-log-history-modal {
  padding: 0 40px 40px;
  position: relative;
  width: 80vw;
  height: 60vh;
  color: $BASE-GREY-COLOR;

  &--header {
    width: calc(80vw - 88px);
    padding-top: 24px;
    z-index: 10;
    background-color: $BASE-WHITE-COLOR;
  }

  &--content {
    height: calc(100% - 180px);
    overflow: auto;
  }

  &--loading-block {
    height: 100px;
    position: relative;
    margin-bottom: 20px;
  }

  &--total-count-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 10px;
    margin: 16px 20px;

    &--title {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }


  &--total-count-info-block {
    font-size: 12px;
    margin-bottom: 10px;

    &--init-owner, &--init-contributor, &--task-owner, &--task-contributor {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &--init-owner-doth, &--init-contributor-doth, &--task-owner-doth, &--task-contributor-doth {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }

    &--init-owner-doth {
      background-color: #f47548;
    }

    &--init-contributor-doth {
      background-color: #006262;
    }

    &--task-owner-doth {
      background-color: $BASE-NORMAL-COLOR;
    }

    &--task-contributor-doth {
      background-color: $BASE-SUCCESS-COLOR;
    }
  }

  &--total-count {
    display: flex;
    width: 400px;
    height: 20px;
    background-color: $BASE-LIGHT-GREY-COLOR;

    &--empty-title {
      width: 100%;
    }

    &--empty-title, &--init-owner, &--init-contr, &--task-owner, &--task-contr {
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      color: $BASE-WHITE-COLOR;
    }

    &--init-owner {
      height: 20px;
      background-color: #f47548;
    }

    &--init-contr {
      height: 20px;
      background-color: #006262;
    }

    &--task-owner {
      height: 20px;
      background-color: $BASE-NORMAL-COLOR;
    }

    &--task-contr {
      height: 20px;
      background-color: $BASE-SUCCESS-COLOR;
    }
  }

  .active-btn {
    background-color: $BASE-DARK-BLUE-COLOR;
  }
}
