@import "src/Assets/styles/variables";
@import "src/Assets/styles/mixins";

.main-layout {
  display: flex;
  min-height: calc(100vh - $BASE-HEADER-HEIGHT);
  background-color: $BASE-WHITE-COLOR;

  &--children {
    position: relative;
    width: 100%;
    padding-left: 0;
    margin: 0 auto;
    background-color: transparent;
  }
}